<template lang="pug">
.container
  page-title
    //- router-link.button.is-primary(:to='createInvoice') Nueva factura
  .card
    .card-content
      locations-table(:items='locations' :is-loading='isLoading' show-contract-info)
</template>

<script>
import LocationsTable from './blocks/LocationsTable'
import { createInvoice } from '@/router/routes'
import locationsService from '@/services/locations.service'

export default {
  components: { LocationsTable },
  data () {
    return {
      locations: [],
      isLoading: false,
      createInvoice
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    async getInvoices () {
      this.isLoading = true
      const result = await locationsService.getAll()
      if (result) {
        this.locations = result
      }
      this.isLoading = false
    }
  }
}
</script>
