<template lang="pug">
b-table(:data='items' :loading='isLoading' narrowed hoverable mobile-cards striped searchable)
  b-table-column(field='name' label='Nombre' sortable searchable v-slot='props')
    //- router-link(:to='{ name: "contract", params: { id: props.row.contractId } }')
    | {{ props.row.name }}
  b-table-column(field='clientName' label='Cliente' sortable searchable v-slot='props') {{ props.row.clientName }}
  b-table-column(field='date' label='Creado' sortable searchable v-slot='props')
    | {{ props.row.date | date }}
  b-table-column(field='public' label='Publico' sortable searchable v-slot='props') {{ props.row.public ? 'Sí' : 'No' }}
  b-table-column(field='codigo' label='Tag' sortable v-slot='props')
    icon(icon='tag' v-if='props.row.code' title='Etiqueta asignada')
  template(slot='empty')
    .no-data-found
  //- TODO: Delete modal?
  b-modal(v-model='invoiceModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal)
    modal-card(v-if='invoiceSelected' title='Editar factura')
      app-form(@submit.prevent='submit' :is-saving='isSaving')
        field(label='Estado' field='status')
          app-select(id='status' :enum='invoiceStatusEnum' :enum-names='invoiceStatusDescriptions' v-model.number='invoiceSelected.status' required)
</template>

<script>
import { invoiceStatusEnum, invoiceStatusDescriptions } from '@/constants/enums'
import invoicesService from '@/services/invoices.service'
import toastService from '@/services/toast.service'

export default {
  components: { },
  props: {
    items: { type: Array, required: true },
    showContractInfo: { type: Boolean },
    isLoading: { type: Boolean }
  },
  data () {
    return {
      invoiceModalActive: false,
      invoiceSelected: null,
      isSaving: false,
      invoiceStatusEnum,
      invoiceStatusDescriptions
    }
  },
  watch: {
    items: {
      inmediate: true,
      handler: function () {
        this.items.forEach(invoice => {
          this.formatItem(invoice)
        })
      }
    }
  },
  methods: {
    formatItem (item) {
      // item.statusDescription = invoiceStatusDescriptions[item.status]
    },
    editInvoice (invoice) {
      this.invoiceSelected = { ...invoice }
      this.invoiceModalActive = true
    },
    async submit () {
      this.isSaving = true
      const result = await invoicesService.save(this.invoiceSelected)
      if (result) {
        const invoice = this.invoices.find(i => i.id === this.invoiceSelected.id)
        toastService.show('Factura actualizada')
        if (invoice) {
          invoice.status = this.invoiceSelected.status
          this.formatInvoice(invoice)
        }
        this.invoiceModalActive = false
      }
      this.isSaving = false
    }
  }
}
</script>
